<template>
  <tr class="new-row">
    <td>
      <input class="input-width" type="text" v-model="dataDate">
    </td>
    <td class="dis">
      <button class="btn-primary btn" v-on:click="saveDate()">Save new Date</button>
    </td>
    <td></td>
    <td></td>
    <td>
      <input class="input-width" type="text" v-model="hourData">
    </td>
    <td></td>
    <td></td>
    <td>
      <button class="btn btn-danger" v-on:click="deleteDate()">Delete new Date</button>
    </td>
  </tr>
</template>

<script>
/**
 * @Creator Keith Strasdas
 * @Release 21.07.2022
 *
 * Das Component TableContentNewRow wird immer dann, wenn man eine neue Reihe hinzufügen möchte aufgerufen.
 * Dieses Component besteht aus einer Tabellenreihe, welche vier Spalten hat.
 *
 * In der ersten Spalte ist ein Eingabefeld, welches ein datum verlangt.
 * Der default Wert dieses Felds ist YYYY-MM-DD.
 *
 * In der zweiten Spalte gibt es einen Button, welcher dazu dient, die Eingaben als neue Reihe zu speichern.
 * Wenn dieser Button gedrückt wird und die Eingaben nicht fehlerhaft waren, wird diese Reihe mit dem Component TableContent erstellt.
 *
 * Die dritte Spalte ist so verrückt, dass diese unter den Arbeitszeiten angezeigt wird.
 * Diese Spalte verlangt eine eingabe der Arbeitszeiten an dem Tag.
 *
 * Die vierte und letzte Spalte steht unter den Bemerkungen und besteht aus einem Button.
 * Wenn dieser Button gedrückt wird, wird die aktuelle Reihe gelöscht.
 */
export default {
  name: "TableContentNewRow",
  data() {
    return {
      time: new Date,
      dataDate: 'YYYY-MM-DD',
      hourData: '0.0'
    }
  },
  props: ['date', 'dataHours', 'hoursMin'],

  methods: {
    /**
     * Die Methode deleteDate() löscht die option eine neue Zeile hinzuzufügen.
     * Diese Methode wird aufgerufen, wenn der "Delete new Date" Button gedrückt wird oder wenn die Eingaben falsch sind.
     */
    deleteDate() {
      this.$store.commit('setAddRow', {value: false});
      sessionStorage.setItem('addRow', JSON.stringify(false));
    },
    /**
     * Die Methode saveData() speichert die Eingaben und erstellt eine neue Reihe mit dem "TableContent.vue" Component.
     * Die Methode wird ausgeführt, wenn der "Save new Date" Button gedrückt wird.
     * Die Methode überprüft auch noch die User-Inputs und führt bei fehlern die deleteData() Methode aus.
     */
    saveDate() {
      let i;
      let exists = true;
      let storage = [];

      while (exists) {
        if (this.hourData.charAt(0) == "0" && this.hourData.charAt(1) != ".") {
          this.hourData = this.hourData.substring(1, this.hourData.length);
        } else {
          exists = false;
        }
      }

      for (i = 0; i < this.$store.state.dates.length; i++) { //Wenn es bereits das Datum gibt, wird der neue Eintrag gelöscht
        if (this.$store.state.dates[i] == this.dataDate) {
          exists = true;
        }
      }
      storage[0] = this.dataDate.substring(0, this.dataDate.indexOf('-'));
      storage[2] = this.dataDate.substring(this.dataDate.indexOf('-') + 1, this.dataDate.length);
      storage[1] = storage[2].substring(0, storage[2].indexOf('-'));
      storage[2] = storage[2].substring(storage[2].indexOf('-') + 1, storage[2].length);

      if (storage[0] == "" || storage[1] == "" || storage[2] == "") {
        exists = true;
      }
      if (this.isANumber(storage[0], '0', '1', '2', '3', '4', '5', '6', '7', '8', '9') || this.isANumber(storage[1], '0', '1', '2', '3', '4', '5', '6', '7', '8', '9') || this.isANumber(storage[2], '0', '1', '2', '3', '4', '5', '6', '7', '8', '9')) {
        exists = true;
      }
      if (!(parseInt(storage[0]) > 2000)) {
        exists = true;
      }
      if (!(parseInt(storage[1]) >= 1 && parseInt(storage[1]) <= 12)) {
        exists = true;
      }
      if (!(parseInt(storage[2]) >= 1 && parseInt(storage[2]) <= 31)) {
        exists = true;
      }

      storage[0] = this.hourData.substring(0, this.hourData.indexOf('.'));
      storage[1] = this.hourData.substring(this.hourData.indexOf('.') + 1, this.hourData.length);
      storage[1] = storage[1].substring(0, 2);
      this.hourData = storage[0] + "." + storage[1];

      if (parseFloat(storage[0]) < 0 || parseFloat(storage[0]) > 23 || parseFloat(storage[1]) < 0 || parseFloat(storage[1]) > 59) { //Wenn man bei der Stunde keine Zahl eingibt, wird die Reihe nicht erstellt
        exists = true;
      }
      if (this.isANumber(storage[0], '0', '1', '2', '3', '4', '5', '6', '7', '8', '9') || this.isANumber(storage[1], '0', '1', '2', '3', '4', '5', '6', '7', '8', '9')) { //Wenn die Stunden was anderes als Ziffern enthalten wird der neue Eintrag gelöscht
        exists = true;
      }
      if (!exists) { //checkt, ob das feld bereits existiert

        this.$store.state.dates.push(this.dataDate); //neues Datum
        this.hoursMin.push(this.hourData); //neue Arbeitszeit
        sessionStorage.setItem('dataDate', JSON.stringify(this.dataDate));
        sessionStorage.setItem('hourData', JSON.stringify(this.hourData));

        this.$store.commit('pushShortCondition', {value: ' '}); //neue Condition
        this.$store.commit('pushComment', {value: ''}); //neuer Kommentar
        this.$store.commit('pushStoredPause', {value: 0}); //neue Pause
        this.$store.commit('setRowAmount', {value: this.$store.state.rowAmount + 1}); //neue Reihe
        this.$store.commit('pushStoredStartSelected', {value: false}); //neue Checkbox
        this.$store.commit('pushStoredEndSelected', {value: false}); //neue Checkbox
        this.$store.commit('pushStoredStartValue', {value: 0}); //neue Startarbeitszeit
        this.$store.commit('pushStoredEndValue', {value: 23.59}); //neue Endarbeitszeit
      }
      this.deleteDate(); //lösche die Reihe
      location.reload();
    },
    /**
     * Die Methode isNumber() vergleicht einen String mit einer beliebig langen abfolge von anderen Strings.
     * Wenn eine Stelle des Strings mit einer Stelle eines anderen Stings übereinstimmt, wird false zurückgegeben.
     * Im anderen Fall wird true zurückgegeben.
     * @param string der Parameter "string" gibt aus, welcher string verglichen werden soll.
     * @param a der Parameter "a" enthält jeden String, welcher mit dem original String verglichen werden soll.
     * @returns {boolean} Die Methode gibt true zurück, wenn ein zeichen des original strings nicht in den a-Strings vorkommt.
     */
    isANumber(string, ...a) {
      let i;
      let b;
      for (i = 0; i < a.length; i++) {
        for (b = 0; b < string.length; b++) {
          if (string.charAt(b) == a[i]) {
            return false;
          }
        }
      }
      return true;
    },
  }
}
</script>

<style scoped>
  .new-row .input-width {
    width: 120px;
  }
</style>