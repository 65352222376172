import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import dataValue from './components/DataValue.vue';
import  tableContent from './components/TableContent.vue';
import pdfScreen from './components/PDFScreen.vue';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.component('data-value', dataValue);
Vue.component('table-content', tableContent);
Vue.component('pdf-screen', pdfScreen);

new Vue({
  router,
  store,
  render: h => h(App),
  el: '#app',
  template: '<App/>',
  components: { App },
}).$mount('#app')

function deleteColumns() {
 let deleteItem = document.getElementsByTagName("a");
 deleteItem.remove();
}

export {
  deleteColumns
};