<template>
  <div id="app" class="container">
    <div class="form-check form-switch dark-mode">
      <!--input class="form-check-input" type="checkbox" role="switch" id="flexSwitchDarkMode" @click="setMode()"/-->
    </div>
    <div v-if="!this.$store.state.dataExists">
      <h1 class="margin-top center">Füge eine .xls Datei hinzu</h1>
      <input type="file" class="form-control form-control-lg" @change="onFileChange">
    </div>
    <div class="results card" v-if="xlsData.data != null && this.$store.state.dataExists" ref="res" id="data">
      <data-value
          :xls-data="xlsData"
          ref="dataValue"></data-value>
      <VueHtml2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="'Stundenzettel_' + xlsData.workerName + '_' + xlsData.dayRange"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="html2Pdf">
        <section slot="pdf-content">
          <pdf-screen :xls-data="xlsData"></pdf-screen>
        </section>
      </VueHtml2pdf>
      <br>
      <ul>
        <li>
          <button class="btn btn-success" @click="generateReport()" v-if="xlsData.data != null">Download als PDF</button>
        </li>
      </ul>
    </div>
    <br>
  </div>
</template>

<script>
/**
 * @Creator Keith Strasdas
 * @Release 21.07.2022
 *
 * Das Programm erstellt ein PDF, welche einen fertigen Stundenzettel enthält.
 * Dieses PDF dokumentiert die Arbeitsstunden mit den dazugehörigen Pausen und Arbeitszeiten, welche der Arbeitnehmer geleistet hat.
 * Dieses dokument ist din-konform und kann nach dem Druck unterschrieben werden.
 *
 * Am Anfang wird der User dazu aufgefordert eine xls-Datei hinzuzufügen.
 * Dies wird mithilfe des XlsCsvParsers gemacht und ausgewertet.
 * Nachdem dies Passiert kommt eine eingabe, welche nicht da sein soll aber der Parser darauf besteht. Dies kann auch meines wissens nach nicht entfernt werden, solange der Parser benutzt wird. >:(
 * Ist dies erledigt wird das Component DataValue mit den erlangten Daten erstellt.
 *
 * "App.vue", ruft die Seite und all ihre Components auf.
 * Der XlsCsvParser und VueHtml2pdf sind beide öffentliche modules, welche "App.vue" benutzt.
 * Die Components DataValue, TableContent, PDFScreen und TableContentNewRow wurden alle für diese App entwickelt.
 */
import * as XLSX from "xlsx";
import VueHtml2pdf from 'vue-html2pdf';
import Data from "@/components/DataValue";
import TableContent from "@/components/TableContent";
import pdfScreen from "@/components/PDFScreen";
export default {
  name: 'App',
  data()  {
    return {
      zwischenspeicher: [],
      //xlsData enthält alle Informationen der eingefügten xls-Datei
      xlsData: {
        data: null,
        workDates: [],
        workHours: {
          workHoursInNumbers: [],
          workHoursInMin: [] //inMin steht für in Minuten
        },
        workerName: '',
        dayRange: '',
        totalHours: 0,
      },
      newRows: {
        dates: [],
        hours: []
      },
      mapping: {
        name: 'Name',
        date: 'Datum'
      },
      darkMode: false
    };
  },
  components: {
    Data,
    VueHtml2pdf,
    TableContent,
    pdfScreen
  },
  methods: {
    /**
     * Die Methode onFileChange wird aufgerufen, wenn eine Datei eingebunden wird.
     * Diese Methode speichert die inhalte der xls-datei in die variable xlsData.data ab.
     * Darüber hinaus werden die anderen variablen und Objekte in dem xlsData Objekt mit den passenden Werten versehen.
     * @param event
     */
    onFileChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' , cellDates: true, dateNF: 'yyyy/mm/dd;@'});
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          this.xlsData.data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.dataTransfer();
          sessionStorage.setItem('data', JSON.stringify(this.xlsData.data));
        }
        reader.readAsBinaryString(this.file);
      }
    },
    dataTransfer() {
      //Hours und Date:
      for (let i = 5; i < this.xlsData.data.length-4; i++) {
        i = this.dataRowTransfer(i);
      }
      //Name:
      this.xlsData.workerName = this.xlsData.data[this.xlsData.data.length-1][0];
      //TotalHours
      this.xlsData.totalHours = this.translateTime(this.xlsData.data[this.xlsData.data.length-1][1]);
      if (this.newRows.dates.length > 0) {
        for (let b = 0; b < this.newRows.dates.length; b++) {
          this.addNewRows(b);
        }
      }
      //Range:
      this.xlsData.dayRange = this.xlsData.data[1][0];
      this.xlsData.dayRange = this.xlsData.dayRange.slice(8, this.xlsData.dayRange.length);

      this.$store.state.dataExists = true;
    },
    dataRowTransfer(i) {
      this.xlsData.workDates.push(this.xlsData.data[i][2]);
      this.xlsData.workHours.workHoursInNumbers.push(this.xlsData.data[i][5]);
      let b = this.xlsData.workDates.length-1;
      this.xlsData.workHours.workHoursInMin[b] = this.translateTime(this.xlsData.workHours.workHoursInNumbers[b]);
      console.log(this.xlsData.workDates[b] == this.xlsData.data[i+1][2] && this.xlsData.workDates[b] != undefined);
      while (this.xlsData.workDates[b] == this.xlsData.data[i+1][2] && this.xlsData.workDates[b] != undefined) {
        i++;
        this.xlsData.workHours.workHoursInNumbers[b] = this.xlsData.workHours.workHoursInNumbers[b] + this.xlsData.data[i][5];
        this.xlsData.workHours.workHoursInMin[b] = this.translateTime(this.xlsData.workHours.workHoursInNumbers[b]);
        //console.log(this.xlsData.workHours.workHoursInMin[b]);
      }
      sessionStorage.setItem('workDates', JSON.stringify(this.xlsData.workDates));
      return i;
    },
    addNewRows(index) {
      this.xlsData.workDates.push(this.newRows.dates[index]);
      this.xlsData.workHours.workHoursInNumbers.push(this.newRows.hours[index]);
      this.xlsData.workHours.workHoursInMin.push(this.newRows.hours[index]);

      this.updateTotalHours(index);
    },
    /**
     * Die Methode updateTotalHours() rechnet die Arbeitszeit des neuen eintrags mit den gesamt Stunden zusammen.
     * Immer wenn eine neue Reihe in dem "TableContent.vue" Component erstellt wird, wird diese Methode aufgerufen.
     */
    updateTotalHours(index) {
      let storage = [];
      this.xlsData.totalHours = this.xlsData.totalHours.toString();

      storage[0] = this.xlsData.totalHours.substring(0, this.xlsData.totalHours.indexOf('.'));
      storage[1] = this.xlsData.totalHours.substring(this.xlsData.totalHours.indexOf('.') + 1, this.xlsData.totalHours.length);
      storage[2] = this.newRows.hours[index].substring(0, this.newRows.hours[index].indexOf('.'));
      storage[3] = this.newRows.hours[index].substring(this.newRows.hours[index].indexOf('.') + 1, this.newRows.hours[index].length);
      if (storage[3].length == 1) {
        storage[3] = storage[3] + "0";
      }

      storage[0] = parseInt(storage[0]) + parseInt(storage[2]);
      storage[1] = parseInt(storage[1]) + parseInt(storage[3]);

      if (parseInt(storage[1]) >= 60) {
        storage[1] = parseInt(storage[1]) - 60;
        storage[0] = parseInt(storage[0]) + 1;
      }
      if (storage[1] == "0") {
        storage[1] = "00";
      }
      this.$store.commit('setTotalHours', {value: storage[0] + ":" + storage[1]});
      this.xlsData.totalHours = storage[0] + "." + storage[1];
    },
    /**
     * Die Methode generateReport() wird ausgeführt, wenn der "Download als PDF" Button gedrückt wird.
     * Diese Methode ist wichtig, um das PDF zu erstellen.
     */
    generateReport () {
      this.$refs.html2Pdf.generatePdf();
    },

    /**
     * Die Methode translateTime() rechnet eine Zahl in eine Zeit um.
     * @param time der Parameter "time" enthält die Zahl, welche in eine Zeit berechnet werden soll.
     * @returns {number} Die Methode gibt "time" als Zeit aus.
     */
    translateTime(time) {
      let min = 0;
      min = Math.floor(time);
      time = time - min;
      time = time * 0.6;
      time = parseFloat(time.toFixed(2));
      time = time + min;
      return time;
    },
    /**
     *
     */
    setMode() {
      this.darkMode = !this.darkMode;
      if (this.darkMode) {
        document.cookie = "mode=dark; expires=Thu, 18 Dec 2050 12:00:00 UTC";
      } else {
        document.cookie = "mode=light; expires=Thu, 18 Dec 2050 12:00:00 UTC";
      }
      this.getMode();
    },
    getMode() {
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf("mode=") == 0) {
          c = c.substring("mode=".length, c.length);
          this.turnDark();
          return c;
        }
      }
      this.turnDark();
      document.cookie = "mode=light; expires=Thu, 18 Dec 2050 12:00:00 UTC";
      return "light";
    },
    turnDark() {
      var element = document.body;
      element.classList.toggle("dark-mode-bg");
      element = document.getElementsByClassName("container");
      element.classList.toggle("dark-mode-bg");
    }
  },
  /**
   * Created wird aufgerufen, wenn die Seite aufgerufen wird oder neu geladen wird.
   * Wenn bereits eine Datei im sessionStorage liegt, wird diese mit den entsprechenden Daten rekonstruierd.
   * Wenn dies nicht der Fall ist, wird die Option geboten eine datei einzuwerfen.
   */
  created() {
    if (JSON.parse(sessionStorage.getItem('data')) != null) {
      if (JSON.parse(sessionStorage.getItem('newRows')) != null) {
        this.newRows = JSON.parse(sessionStorage.getItem('newRows'));
      }
      this.xlsData.data = JSON.parse(sessionStorage.getItem('data'));
      this.dataTransfer();
      if (JSON.parse(sessionStorage.getItem('dataDate')) != null) {
        this.xlsData.workDates.push(JSON.parse(sessionStorage.getItem('dataDate')));
        this.xlsData.workHours.workHoursInNumbers.push(JSON.parse(sessionStorage.getItem('hourData')));
        let b = this.translateTime(JSON.parse(sessionStorage.getItem('hourData')));
        this.xlsData.workHours.workHoursInMin.push(b);

        this.newRows.hours.push(JSON.parse(sessionStorage.getItem('hourData')));
        this.newRows.dates.push(JSON.parse(sessionStorage.getItem('dataDate')));
        sessionStorage.removeItem('hourData');
        sessionStorage.removeItem('dataDate');
        sessionStorage.setItem('newRows', JSON.stringify(this.newRows));

        sessionStorage.removeItem('data');
        sessionStorage.setItem('data', JSON.stringify(this.xlsData.data));
      }

      if (sessionStorage.getItem('startSelected') != null) {
        for (let i = 0; i < JSON.parse(sessionStorage.getItem('startSelected')).length; i++) {
          this.zwischenspeicher = JSON.parse(sessionStorage.getItem('startSelected'));
          this.$store.state.storedStart.selected[i] = this.zwischenspeicher[i];
        }
      }

      if (sessionStorage.getItem('endSelected') != null) {
        for (let i = 0; i < JSON.parse(sessionStorage.getItem('endSelected')).length; i++) {
          this.zwischenspeicher = JSON.parse(sessionStorage.getItem('endSelected'));
          this.$store.state.storedEnd.selected[i] = this.zwischenspeicher[i];
        }
      }

      if (sessionStorage.getItem('startValue') != null) {
        for (let i = 0; i < JSON.parse(sessionStorage.getItem('startValue')).length; i++) {
          this.zwischenspeicher = JSON.parse(sessionStorage.getItem('startValue'));
          this.$store.state.storedStart.value[i] = this.zwischenspeicher[i];
        }
      }

      if (sessionStorage.getItem('endValue') != null) {
        for (let i = 0; i < JSON.parse(sessionStorage.getItem('endValue')).length; i++) {
          this.zwischenspeicher = JSON.parse(sessionStorage.getItem('endValue'));
          this.$store.state.storedEnd.value[i] = this.zwischenspeicher[i];
        }
      }
    }
    //Dark Mode\\
    //let mode = this.getMode();
    //if (mode == "light") {
    //  this.turnDark();
    //}
    //‚console.log(mode);
  }
};
</script>

<style>
.dark-mode-bg {
  background-color: RGB(45,45,45);
  color: white;
}
  .margin-top {
    margin: 2% 0;
  }
  .btn-success {
    margin-bottom: 50px;
  }
  /*
   * Table
   */
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  .gray {
    color: gray;
  }
  .btn-success {
    width: 200px;
  }
  .time {
    height: 38px;
  }
  .results {
    padding: 8px;
    margin-top: 24px;
  }
  .dark-mode {
    margin-top: 12px;
    transform: translateX(-96px);
    position: fixed;
  }
  #flexSwitchDarkMode {
    min-height: 32px;
    min-width: 64px;
  }
</style>