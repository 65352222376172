<template>
    <tr>
      <td v-if="start.selected[index] == false && end.selected[index] == false">{{ date[index] }}</td>
      <td v-else @click="updateNewData"><button class="btn btn-primary">Ändern</button></td>
      <td class="dis">
        <input type="checkbox" v-model="start.selected[index]">
        <label v-model="start.value[index]" @change="start.value[index]" v-if="start.selected[index] == false" class="table-hour">{{ this.conditionBlock(this.timeFormat(start.value[index]), this.index, " Uhr") }}</label>
        <input v-else type="time" v-model="newData" class="timeInput">
      </td>
      <td class="dis">
        <input type="checkbox" v-model="end.selected[index]">
        <label class="table-hour" v-if="end.selected[index] == false">{{ this.conditionBlock(this.timeFormat(end.value[index]), this.index, " Uhr") }}</label>
        <input v-else type="time" v-model="newData" class="timeInput">
      </td>
      <td :class="this.classObj(index, this.pause, this.hoursMin, this.danger)" v-model="$store.state.storedPause[index]" class="pauseWidth">{{ this.conditionBlock(this.timeFormat(this.calculatePause(index, this.pause, this.hoursMin)), this.index, " h") }}</td>
      <td>{{ this.conditionBlock(this.timeFormat(hoursMin[index]), this.index, " h") }}</td>
      <td>
        <select id="options" class="condition center" v-model="$store.state.shortCondition[index]">
          <option value="K">K</option>
          <option value="U">U</option>
          <option value="UU">UU</option>
          <option value="F">F</option>
          <option value="SA">SA</option>
          <option value="">Leer</option>
        </select>
      </td>
      <td>
        <p>{{ time.getDate() }}.{{ time.getMonth()+1 }}.{{ time.getFullYear() }}</p>
      </td>
      <td>
        <input type="text" v-model="$store.state.comment[index]">
      </td>
    </tr>
</template>

<script>
/**
 * @Creator Keith Strasdas
 * @Release 21.07.2022
 *
 * Das Component TableContent enthält die einzelnen Reihen der Tabelle.
 *
 * In der ersten Spalte steht das Datum des Arbeitstags, welcher in der Reihe relevant ist.
 *
 * In der zweiten Spalte steht die Uhrzeit, welche den Arbeitsstart des Tages markiert.
 * Da die xls-dateien von Jira nicht diese Information enthalten muss diese Manuell eingegeben werden.
 * Der default Wert von dieser Zeit ist 0:00 Uhr.
 *
 * In der dritten Spalte steht die Uhrzeit, welche das Arbeitsende des Tages markiert.
 * Da die xls-dateien von Jira nicht diese Information enthalten muss diese Manuell eingegeben werden.
 * Der default Wert von dieser Zeit ist 23:59 Uhr.
 *
 * In der vierten Spalte stehen die Pausen des Tages.
 * Diese können nicht geändert werden und werden anhand der Start-, End- und Arbeitszeit errechnet.
 *
 * In der fünften Spalte steht die Arbeitszeit abzüglich der Pausen des Tages.
 *
 * In der sechsten Spalte kann man besondere Kürzel eintragen, welche wichtig für den Arbeitstag sind.
 *
 * In der siebten Spalte steht das Datum des Tages, an welchem dieses dokument erstellt wurde.
 *
 * Abschließend ist in der achten Spalte ein freies Feld, in dem man eine Bemerkung eintragen kann.
 */
export default {
  name: 'TableContent',

  data() {
    return {
      start: {
        selected: this.$store.state.storedStart.selected,
        value: this.$store.state.storedStart.value,
      },
      end: {
        selected: this.$store.state.storedEnd.selected,
        value: this.$store.state.storedEnd.value,
      },
      newData: '',
      pause: [],
      danger: [],
      time: new Date
    };
  },
  props: ['date', 'dataHours', 'index', 'hoursMin'],

  computed: {
  },

  watch: {
    'start.selected': function () {
      sessionStorage.setItem('startSelected', JSON.stringify(this.$store.state.storedStart.selected));
    },
    'end.selected': function () {
      sessionStorage.setItem('endSelected', JSON.stringify(this.$store.state.storedEnd.selected));
    },
    'start.value': function () {
      sessionStorage.setItem('startValue', JSON.stringify(this.$store.state.storedStart.value));
    },
    'end.value': function () {
      sessionStorage.setItem('endValue', JSON.stringify(this.$store.state.storedEnd.value));
    },
    '$store.state.shortCondition': function () {
      sessionStorage.setItem('shortCondition', JSON.stringify(this.$store.state.shortCondition));
    },
    '$store.state.comment': function () {
      sessionStorage.setItem('comment', JSON.stringify(this.$store.state.comment));
    }
  },

  methods: {
    /**
     * Die Methode updateNewData() wird immer dann aufgerufen, wenn der Ändern-Button in der Datumsspalte gedrückt wird.
     * Die Methode verändert den Inhalt der eingegebenen Zeit und verändert diesen in ein Format, welches berechnet werden kann.
     */
    updateNewData() {
      (this.newData == '') ? this.newData = "0.00": this.newData = this.newData.toString();
      this.newData = this.newData.replace(":", ".");
      this.newData = parseFloat(this.newData);

      if (this.$store.state.storedStart.selected[this.index]) {
        //this.$store.commit('setStartValue', {value: this.newData, data: this.index});
        this.$store.state.storedStart.value[this.index] = this.newData; //value muss erreichbar sein. Wenn es nicht erreichbar ist, dann ist das entweder nicht geupdatet oder der array ist zu klein
        this.$store.commit('setStartSelected', {selected:false, data:this.index});
        sessionStorage.setItem('startSelected', JSON.stringify(this.$store.state.storedStart.selected));
        sessionStorage.setItem('startValue', JSON.stringify(this.$store.state.storedStart.value));
      }
      if (this.$store.state.storedEnd.selected[this.index]) {
        //this.$store.commit('setEndValue', {value: this.newData, data: this.index});
        this.$store.state.storedEnd.value[this.index] = this.newData;
        this.$store.commit('setEndSelected', {selected:false, data:this.index});
        sessionStorage.setItem('endSelected', JSON.stringify(this.$store.state.storedEnd.selected));
        sessionStorage.setItem('endValue', JSON.stringify(this.$store.state.storedEnd.value));
      }
      this.newData = '';
    },

    /**
     * Die Methode conditionBlock() wird bei allen Zeitwerten aufgerufen.
     * Wenn die eingegebene Condition nicht "SA" oder "leer" ist, wird die Zeit als "--:--" dargestellt.
     * @param input der Parameter "input" enthält die Zeit, welche eigentlich dargestellt werden sollte.
     * @param index der Parameter "index" enthält die Reihe, welche betrachtet wird.
     * @param time der Parameter "time" enthält einen String, welcher nach dem "input" ausgegeben werden soll. Dieser ist meistens " h" oder " Uhr".
     * @returns {string|*} Die Methode gibt entweder "--:--" oder "input + time" zurück.
     */
    conditionBlock(input, index, time) {
      if (this.$store.state.shortCondition[index] == "K" || this.$store.state.shortCondition[index] == "U" || this.$store.state.shortCondition[index] == "UU" || this.$store.state.shortCondition[index] == "F") {
        return "--:--";
      } else {
        return input + time;
      }
    },
    /**
     * Die Methode calculatePause() rechnet aus, wie lang die Pause sein am gegebenen Tag gewesen sein muss.
     * calculatePause() updatet den wert der Pause immer dann, wenn entweder der "storedEnd.value" oder der "storedStart.value" Wert geändert wird.
     * @param data der Parameter "data" enthält die Reihe, auf welche zugegriffen werden soll.
     * @param pause der Parameter "pause" enthält den Wert der Pause, damit dieser geändert und global gespeichert werden kann.
     * @param hoursMin der Parameter "hoursMin" enthält die Arbeitszeit.
     * @returns {*} Die Methode gibt die Pause der Reihe zurück.
     */
    calculatePause(data, pause, hoursMin) {
      pause[data] = this.translateTime(this.$store.state.storedEnd.value[data], false) -  this.translateTime(hoursMin[data], false) - this.translateTime(this.$store.state.storedStart.value[data], false);
      pause[data] = this.translateTime(pause[data], true);

      if (pause[data] < 0) { //verhindert negative pausen
        pause[data] = 0;
      }
      this.$store.commit('setPause', {pause: pause[data], data: data});

      return pause[data];
    },
    /**
     * Die Methode classObj() wird bei jeder änderung des Pause-Werts aufgerufen.
     * Wenn die Pause nicht ausreichend für die Arbeitszeit ist, bekommt die <td> die Klasse ".red".
     * Diese Klasse färbt den inhalt der <td> rot, um den Fehler zu signalisieren.
     * @param data der Parameter "data" gibt die betroffene Reihe an.
     * @param pause der Parameter "pause" gibt die Pause-Werte an.
     * @param hoursMin der Parameter "hoursMin" gibt die Arbeitsstunden an.
     * @param danger der Parameter "danger" ist ein Array, welcher bestimmt, ob die gegebene <td> die Klasse ".red" haben soll oder nicht.
     * @returns {{red: *, notRed: boolean}} Die Methode gibt zurück, ob die <td> die Klasse ".red" oder die Klasse ".notRed" bekommen soll.
     */
    classObj(data, pause, hoursMin, danger) {
      if (pause[data] < 0.3 && hoursMin[data] > 4) { //Im Case von 4 arbeitsstunden braucht man keine Pause
        danger[data] = true;
      } else if (this.translateTime(this.translateTime(this.$store.state.storedEnd.value[data], false) -  this.translateTime(hoursMin[data], false) - this.translateTime(this.$store.state.storedStart.value[data], false), true) < 0) { //Wenn die Arbeitszeit keinen Sinn ergibt
        danger[data] = true;
      } else {
        danger[data] = false;
      }
      return { red: danger[data], notRed: !danger[data] };
    },
    /**
     * Die Methode translateTime() rechnet eine Zahl in eine Uhrzeit um.
     * Alternativ kann die Methode auch eine Uhrzeit in eine Zahl umrechnen.
     * @param time der Parameter "time" enthält die Zahl oder Zeit, welche umgerechnet werden soll.
     * @param bool der Parameter "bool" ist ein boolean, welcher bei bestimmt, ob das ergebnis eine Zahl oder eine Zeit sein soll.
     * @returns {number} Die Methode gibt "time" als die entgegengesetzte einheit aus.
     */
    translateTime(time, bool) {
      let min = 0;
      min = Math.floor(time);
      time = time - min;
      if (bool) {
        time = time * 0.6;
      } else {
        time = time / 0.6;
      }
      time = parseFloat(time.toFixed(2));
      time = time + min;
      return Math.round(time*100)/100;
    },
    /**
     * Die Methode timeFormat() wird benutzt, um Zahlen in Zeiten umzuformatieren.
     * Hierbei wird darauf geachtet, dass alles nach dem Bruch die Minuten bestimmt.
     * @param time Der Parameter time ist die Zahl, welche umformatiert werden soll.
     * @returns {*} Die Methode gibt die Zahl als Zeit aus.
     */
    timeFormat(time) {
      if (!time.toString().includes(".")) { //Wenn die Zahl keinen Bruch enthält, werden die Minuten als ":00" dargestellt
        time = time + ":00";
      } else {
        time = time.toString().replace(".",":")
      }
      if (time.charAt(2) == ":" && time.length == 4 || time.charAt(1) == ":" && time.length == 3) { //Wenn nach dem Bruch nur eine Stelle kommt, wird noch eine 0 hinten dran gehangen.
        time = time + "0";
      }
      if (time.charAt(2) == ":" && time.length > 5) {
        time = time.slice(0, 5);
      }
      if (time.charAt(1) == ":" && time.length > 4) {
        time = time.slice(0, 4);
      }
      return time;
    },
  },
  /**
   * created() wird ausgeführt, wenn das Component "TableContent.vue" erstellt wird.
   * Wenn created() aufgerufen wird, werden alle Werte, welche in dem Component gebraucht werden auf default werde geändert, damit diese später einfacher verändert werden können.
   */
    created() {
      for (let i = 0; i < this.date.length && this.$store.state.firstRow; i++) {
        this.pause[i] = 0;
        this.$store.commit('setPause', {value:0, data:i});

        if (sessionStorage.getItem('comment') != null) {
          this.$store.commit('setComment',{value: JSON.parse(sessionStorage.getItem('comment'))[i], data:i});
        } else {
          this.$store.commit('setComment', {value:'', data:i});
        }
        if (sessionStorage.getItem('shortCondition') != null) {
          this.$store.commit('setShortCondition',{value: JSON.parse(sessionStorage.getItem('shortCondition'))[i], data:i});
        } else {
          this.$store.commit('setShortCondition',{value: ' ', data:i});
        }
        if (sessionStorage.getItem('startValue') != null) {
          this.start.value[i] = JSON.parse(sessionStorage.getItem('startValue'))[i];
        } else {
          this.start.value[i] = 0;
        }
        if (sessionStorage.getItem('endValue') != null) {
          this.end.value[i] = JSON.parse(sessionStorage.getItem('endValue'))[i];
        } else {
          this.end.value[i] = 23.59;
        }
        if (sessionStorage.getItem('startSelected') != null) {
          this.start.selected[i] = JSON.parse(sessionStorage.getItem('startSelected'))[i];
        } else {
          this.start.selected[i] = false;
        }
        if (sessionStorage.getItem('endSelected') != null) {
          this.end.selected[i] = JSON.parse(sessionStorage.getItem('endSelected'))[i];
        } else {
          this.end.selected[i] = false;
        }
        this.danger[i] = false;
      }
      this.$store.commit('setFirstRow', {value:false});
      this.$store.commit('setRowAmount', {value:this.$store.state.rowAmount + 1});
  },
}
</script>

<style>
.tr {
  color: white;
}
  .table-hour {
    margin-left: 8px;
  }
  .red {
    color: red;
  }
  .notRed {
    color: black;
  }
  .dis {}

  .condition {
    width: 55px;
  }
  .timeInput {
    margin-left: 5px;
    width: 90px;
    transform: translateY(-1px);
  }
</style>
