<template>
  <section class="section">
    <div class="head-box">
      <span></span>
      <h1 class='bold'>Stundenzettel</h1>
      <button class="btn btn-outline-danger head-box-button" @click="deleteData">x</button>
    </div>
    <br>
    <div class="row">
      <h4 class="col-3">Firma:</h4><h4 class="col">konpio GmbH</h4>
    </div>
    <div class="row">
      <h4 class="col-3">Name:</h4><h4 class="col">{{ xlsData.workerName }}</h4>
    </div>
    <div class="row">
      <h4 class="col-3">Vom:</h4><h4 class="col">{{ xlsData.dayRange }}</h4>
    </div>
    <div class="row">
      <h4 class="col-3">Summe der Stunden:</h4><h4 class="col">{{ this.$store.state.totalHours }} h</h4>
    </div>
    <div class="row">
      <h4 class="col-3">Pers. -Nr:</h4><input class="col-1" v-model="$store.state.persNr">
    </div>
    <br>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            <p class='bold'>Datum</p>
          </th>
          <th scope="col">
            <p class='bold'>Beginn</p>
          </th>
          <th scope="col">
            <p class='bold'>Ende</p>
          </th>
          <th scope="col">
            <p class='bold pause'>Pausen</p>
          </th>
          <th scope="col">
            <p class='bold timeNoPause'>Arbeitszeit</p>
            <span class='gray'>(abzüglich Pausen)</span>
          </th>
          <th scope="col">
            <p>*</p>
          </th>
          <th scope="col">
            <p>aufgezeichnet am:</p>
          </th>
          <th scope="col">
            <p>Bemerkungen</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <table-content v-for="(item, index) in xlsData.workDates" :date="xlsData.workDates" :dataHours="xlsData.workHours.workHoursInNumbers" :index="index" :hoursMin="xlsData.workHours.workHoursInMin" :key="item" ref="start end"></table-content>
        <table-content-new-row v-if="this.$store.state.addRow" :dataHours="xlsData.workHours.workHoursInNumbers" :hoursMin="xlsData.workHours.workHoursInMin"></table-content-new-row>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <button @click="addNewDate()" class="btn btn-primary">Add new date</button>
          </td>
          <td>
            <button @click="selectAll('start')" class="btn btn-primary">Select all begin</button>
          </td>
          <td>
            <button @click="selectAll('end')" class="btn btn-primary">Select all end</button>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
            <input type="time" v-model="time" class="time">
          </td>
          <td>
            <button class="btn btn-primary" @click="changeData()">Ändern</button>
          </td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <p>* Tragen Sie in diese Spalte eines der folgenden Kürzel ein, wenn es für diesen Kalendertag zutrifft</p>
    <ul>
      <li class="row">
        <span class="col-1">K</span><span class="col">Krank</span>
      </li>
      <li class="row">
        <span class="col-1">U</span><span class="col">Urlaub</span>
      </li>
      <li class="row">
        <span class="col-1">UU</span><span class="col">unbezahlter Urlaub</span>
      </li>
      <li class="row">
        <span class="col-1">F</span><span class="col">Feiertag</span>
      </li>
      <li class="row">
        <span class="col-1">SA</span><span class="col">Stundenweise abwesend</span>
      </li>
    </ul>
  </section>
</template>

<script>
/**
 * @Creator Keith Strasdas
 * @Release 21.07.2022
 *
 * DataValue.vue wird erstellt, wenn der XlsCsvParser aus der xls-datei die nötigen Informationen bekommen hat und diese an den Store gesendet hat.
 * DataValue.vue erstellt eine benutzeroberfläche, welche aus drei Teilen besteht.
 * 1. Allgemeine Informationen. Darunterfällt der Firmenname, der Benutzername, von wann bis wann die Daten ausgelesen werden, die gesamtsumme der Stunden und die Personen Nummer.
 * 2. Tabelleninformationen. Diese werden mit den Components TableContent.vue und TableContentNewRow.vue erstellt.
 * 3. Benutzereingaben. Dieser Bereich besteht aus Buttons, welche es den Nutzer erlauben die Daten der Tabelle zu verändern und das fertige Dokument als PDF zu speichern.
 */
import TableContent from "@/components/TableContent";
import TableContentNewRow from "@/components/TableContentNewRow";

export default {
  name: "Data",
  data() {
    return {
      persNr: '',
      newRow: 0,
      time: null,
      danger: []
    };
  },
  components: {
    TableContentNewRow,
    TableContent
  },

  watch: {
    '$store.state.persNr': function () {
      sessionStorage.setItem('persNr', JSON.stringify(this.$store.state.persNr));
    },
  },

  methods: {
    /**
     * Die Methode deleteData() wird aufgerufen, wenn der x-Button gedrückt wird.
     * Die Methode löscht die Daten der xlsDatei und setzt die globalen store variablen zurück.
     */
    deleteData() {
      this.xlsData.data = null;
      this.xlsData.workerName = '';
      this.xlsData.workHours.workHoursInMin = [];
      this.xlsData.workHours.workHoursInNumbers = [];
      this.xlsData.workDates = [];
      this.xlsData.dayRange = '';
      this.xlsData.totalHours = 0;
      this.$store.commit('setDataExists', {value:false});
      this.$store.commit('deleteRows');
      this.$store.commit('setFirstRow', {value:true});

      sessionStorage.clear();
      location.reload();
    },
    /**
     * Die Methode updateDate() speichert das datum in den globalen store.
     */
    updateDate() {
      this.$store.commit('setDates', {value:this.xlsData.workDates});
    },
    /**
     * Die Methode addNewDate() wird ausgeführt, wenn der "Add new date" Button gedrückt wird.
     * Die Methode erstellt eine neue Reihe, welche durch das TableContentNewRow.vue component erstellt wird.
     */
    addNewDate() {
      this.newRow++;
      this.newRowCreated();
    },
    /**
     * Die Methode selectAll() wird durchgeführt, wenn entweder der "Select all beginn" oder der "Select all end" Button gedrückt wird.
     * Die Methode invertiert die eingabe aller checkboxen des ausgewählten Bereiches.
     * @param type: gibt an, welchen Button der User gedrückt hat
     */
    selectAll(type) {
      let l = this.$store.state.storedStart.selected.length;
      if (type == 'start') {
        this.$store.dispatch('startSelected', {l:l});
        sessionStorage.setItem('startSelected', JSON.stringify(this.$store.state.storedStart.selected));
        location.reload();
      } if (type == 'end') {
        this.$store.dispatch('endSelected', {l:l});
        sessionStorage.setItem('endSelected', JSON.stringify(this.$store.state.storedEnd.selected));
        location.reload();
      }
    },
    /**
     * Die Methode changeData() wird aufgerufen, wenn der "Ändern" Button gedrückt wird.
     * Alle Werte, welche von einer Checkbox ausgewählt wurden bekommen, jetzt die Zeit zugewiesen, welche in dem time input feld eingegeben wurde.
     * Danach verlieren alle Checkboxen ihre Auswahl und der inhalt des time input felds wird zurückgesetzt.
     */
    changeData() {
      this.time = this.time.toString();
      this.time = this.time.replace(":", ".");
      this.time = parseFloat(this.time);

      for(let i = 0; i < this.$store.state.rowAmount; i++) {
        if (this.$store.state.storedStart.selected[i] == true) {
          this.$store.state.storedStart.value[i] = this.time; //value muss erreichbar sein. Wenn es nicht erreichbar ist, dann ist das entweder nicht geupdatet oder der array ist zu klein
          //this.$store.commit('setStartValue', {value: this.time, data:i});
          this.$store.commit('setStartSelected', {selected:false, data:i});
        }
        if (this.$store.state.storedEnd.selected[i] == true) {
          this.$store.state.storedEnd.value[i] = this.time;
          //this.$store.commit('setEndValue', {value: this.time, data:i});
          this.$store.commit('setEndSelected', {selected:false, data:i});
        }
      }
      sessionStorage.setItem('startValue', JSON.stringify(this.$store.state.storedStart.value));
      sessionStorage.setItem('endValue', JSON.stringify(this.$store.state.storedEnd.value));
      sessionStorage.setItem('endSelected', JSON.stringify(this.$store.state.storedEnd.selected));
      sessionStorage.setItem('startSelected', JSON.stringify(this.$store.state.storedStart.selected));
      location.reload();
    },
    /**
     * Die Methode timeFormat() wird benutzt, um Zahlen in Zeiten umzuformatieren.
     * Hierbei wird darauf geachtet, dass alles nach dem Bruch die Minuten bestimmt.
     * @param time Der Parameter time ist die Zahl, welche umformatiert werden soll.
     * @returns {string} Die Methode gibt die Zahl als Zeit aus.
     */
    timeFormat(time) {
      if (!time.toString().includes(".")) { //Wenn die Zahl keinen Bruch enthält, werden die Minuten als ":00" dargestellt
        time = time + ":00";
      } else {
        time = time.toString().replace(".",":");
      }
      if (time.charAt(2) == ":" && time.length == 4 || time.charAt(1) == ":" && time.length == 3) { //Wenn nach dem Bruch nur eine Stelle kommt, wird noch eine 0 hinten dran gehangen.
        time = time + "0";
      }
      return time.toString();
    },
    /**
     * Die Methode newRowCreated() wird aufgerufen, wenn eine neue Reihe hinzugefügt werden soll.
     * Die Methode erstellt einen neuen eintrag in dem danger Array, damit die Pausen rot markiert werden können.
     * Der state "addRow" im globalen speicher kann nur neue Reihen hinzufügen, wenn dieser false ist.
     * Da die Methode "addRow" auf true setzt, kann nur eine neue Reihe gleichzeitig hinzugefügt werden.
     */
    newRowCreated() {
      this.$store.commit('setAddRow', {value: true});
      sessionStorage.setItem('addRow', JSON.stringify(true));
      this.danger.push(false);
    },
  },
  /**
   * Created() wird aufgerufen, wenn das Component "DataValue.vue" erstellt wird.
   * Created() speichert Daten in den globalen speicher, damit andere Components darauf zugreifen können.
   */
  created() {
    this.updateDate();
    this.$store.commit('setTotalHours', {value: this.timeFormat(this.xlsData.totalHours)});
    if (sessionStorage.getItem('persNr') != null) {
      this.$store.state.persNr = JSON.parse(sessionStorage.getItem('persNr'));
    }
  },
  props: ['xlsData']
}
</script>

<style>
  .section-dark-mode-bg {
    background-color: RGB(70,70,70);
    color: white;
  }
  .time {
    width: 90px;
  }
  .pause {
    width: 80px;
  }
  .timeNoPause {
    margin: 0;
  }
  .head-box {
    display: flex;
    justify-content: space-between;
  }
  .head-box-button {
    height: min-content;
    width: 37px;
    border-radius: 30px !important;
  }

</style>