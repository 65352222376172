import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    firstRow: true,
    dataExists: false,  //wenn dataExists true ist, wird DataValue angezeigt
    rowAmount: 0,    //die Anzahl der Reihen, welche in der Tabelle enthalten sind
    persNr: '',      //enthält die Personennummer
    newData: '',
    addRow: false,   //wenn addRow true ist wird das TableContentNewRow Component dargestellt
    totalHours: 0,   //enthält die Anzahl der gesamtstunden

    storedStart: {   //alle infos für die Startzeit
      selected: [],  //wird mit booleans befüllt und gibt den Status der Checkboxen an
      value: [],     //enthält die Zeit
    },
    storedEnd: {     //alle infos für die Endzeit
      selected: [],  //wird mit booleans befüllt und gibt den Status der Checkboxen an
      value: [],     //enthält die Zeit
    },

    dates: [],       //enthält das Datum der individuellen Tage
    storedPause: [], //enthält die Pause zeiten
    shortCondition: [], //enthält die Kürzel der einzelnen Tage
    comment: []     //enthält den Inhalt der Kommentarfelder

  },
  getters: {
  },
  mutations: {
    setPersNr(state, payload) {
      state.persNr = payload;
    },
    setPause(state, payload) {
      state.storedPause[payload.data] = payload.pause;
    },
    setStartSelected(state, payload) {
      state.storedStart.selected[payload.data] = payload.selected;
    },
    setStartValue(state, payload) {
      state.storedStart.value[payload.data] = payload.value[payload.data];
    },
    setEndSelected(state, payload) {
      state.storedEnd.selected[payload.data] = payload.selected;
    },
    setEndValue(state, payload) {
      state.storedEnd.value[payload.data] = payload.value[payload.data];
    },
    setTotalHours(state, payload) {
      state.totalHours = payload.value;
    },
    setAddRow(state, payload) {
      state.addRow = payload.value;
    },
    setDates(state, payload) {
      state.dates = payload.value;
    },
    setRowAmount(state, payload) {
      state.rowAmount = payload.value;
    },
    setFirstRow(state, payload) {
      state.firstRow = payload.value;
    },
    setShortCondition(state, payload) {
      state.shortCondition[payload.data] = payload.value;
    },
    setComment(state, payload) {
      state.comment[payload.data] = payload.value;
    },
    setDataExists(state, payload) {
      state.dataExists = payload.value;
    },

    pushShortCondition(state, payload) {
      state.shortCondition.push(payload.value);
    },
    pushComment(state, payload) {
      state.comment.push(payload.value);
    },
    pushStoredPause(state, payload) {
      state.storedPause.push(payload.value);
    },
    pushStoredStartValue(state, payload) {
      state.storedStart.value.push(payload.value);
    },
    pushStoredEndValue(state, payload) {
      state.storedEnd.value.push(payload.value);
    },
    pushStoredStartSelected(state, payload) {
      state.storedStart.selected.push(payload.value);
    },
    pushStoredEndSelected(state, payload) {
      state.storedEnd.selected.push(payload.value);
    },

    deleteRows(state) {
      state.rowAmount = 0;
      state.persNr = '';
      state.totalHours = 0;

      while(state.storedEnd.selected.length != 0) {
        state.storedStart.value.pop();
        state.storedStart.selected.pop();
        state.storedPause.pop();
        state.comment.pop();
        state.shortCondition.pop();
        state.storedEnd.value.pop();
        state.storedEnd.selected.pop();
      }
    }
  },
  actions: {
    startSelected(context, payload) {
      for(let i = 0; i < payload.l; i++) {
        context.commit('setStartSelected', {selected: true, data: i});
      }
    },
    endSelected(context, payload) {
      for(let i = 0; i < payload.l; i++) {
        context.commit('setEndSelected', {selected: true, data: i});
      }
    },
  },
  modules: {
  }
})
