<template>
  <section class="container pdfScreen">
    <div style="height: 1mm;"></div> <!--- Wenn das fehlt bricht die Darstellung bei min 20 einträgen. Keine Ahnung warum --->
    <h3 class='bold heading'>Vorlage zur Dokumentation der täglichen Arbeitszeit</h3>
    <br>
    <div class="offset">
      <div class="row">
        <p class="col-3">Firma:</p><p class="col">konpio GmbH</p>
      </div>
      <div class="row">
        <p class="col-3">Name:</p><p class="col">{{ xlsData.workerName }}</p>
      </div>
      <div class="row">
        <p class="col-3">Vom:</p><p class="col">{{ xlsData.dayRange }}</p>
      </div>
      <div class="row">
        <p class="col-3">Summe der Stunden:</p><p class="col">{{ this.$store.state.totalHours }} h</p>
      </div>
      <div class="row">
        <p class="col-3">Pers. -Nr:</p><p class="col">{{ $store.state.persNr }}</p>
      </div>
    </div>
    <table class="table offset">
      <thead>
      <tr>
        <th scope="col">
          <p class='bold'>Datum</p>
        </th>
        <th scope="col">
          <p class='bold'>Beginn</p>
        </th>
        <th scope="col">
          <p class='bold'>Ende</p>
        </th>
        <th scope="col">
          <p class='bold'>Pausen</p>
        </th>
        <th scope="col">
          <p class='bold timeNoPause'>Arbeitszeit</p>
          <span class='gray'>(abzüglich Pausen)</span>
        </th>
        <th scope="col condition">
          <p>*</p>
        </th>
        <th scope="col">
          <p class="timeNoPause">aufgezeichnet</p>
          <span>am:</span>
        </th>
        <th scope="col">
          <p>Bemerkungen</p>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in $store.state.rowAmount - this.newRows">
        <td>{{ $store.state.dates[index] }}
          <div v-if="index == 18" style="height: 92mm;"></div>
        </td>
        <td>
          <label>{{ conditionBlock(timeFormat($store.state.storedStart.value[index]), index, " Uhr")}}</label>
        </td>
        <td>
          <label>{{ conditionBlock(timeFormat($store.state.storedEnd.value[index]), index, " Uhr")}}</label>
        </td>
        <td>{{ conditionBlock(timeFormat($store.state.storedPause[index]), index, " h")}}</td>
        <td>{{ conditionBlock(timeFormat(xlsData.workHours.workHoursInMin[index]), index, " h")}}</td>
        <td class="condition">{{ $store.state.shortCondition[index] }}</td>
        <td>{{ currentDate.getDate() }}.{{ currentDate.getMonth()+1 }}.{{ currentDate.getFullYear() }}</td>
        <td class="comment">{{ $store.state.comment[index] }}</td>
      </tr>
      </tbody>
    </table>
    <p class="offset">* Tragen Sie in diese Spalte eines der folgenden Kürzel ein, wenn es für diesen Kalendertag zutrifft</p>
    <ul class="offset">
      <li class="row">
        <span class="col-1">K</span><span class="col">Krank</span>
      </li>
      <li class="row">
        <span class="col-1">U</span><span class="col">Urlaub</span>
      </li>
      <li class="row">
        <span class="col-1">UU</span><span class="col">unbezahlter Urlaub</span>
      </li>
      <li class="row">
        <span class="col-1">F</span><span class="col">Feiertag</span>
      </li>
      <li class="row">
        <span class="col-1">SA</span><span class="col">Stundenweise abwesend</span>
      </li>
    </ul>
    <div class="height"></div>

    <div class="signitureBox offset">
      <div class="signiture">
        <div class="signitureLine">
          <span class="height">{{ currentDate.getDate() }}.{{ currentDate.getMonth()+1 }}.{{ currentDate.getFullYear() }}</span>
        </div>
        <div class="signitureText">
          <p>Datum</p>
          <p>Unterschrift des Arbeitnehmers</p>
        </div>
      </div>

      <div class="signiture">
        <div class="signitureLine">
          <span class="height">{{ currentDate.getDate() }}.{{ currentDate.getMonth()+1 }}.{{ currentDate.getFullYear() }}</span>
        </div>
        <div class="signitureText">
          <p>Datum</p>
          <p>Unterschrift des Arbeitgebers</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/**
 * @Creator Keith Strasdas
 * @Release 21.07.2022
 *
 * Das Component PDFScreen enthält das Design, welches das PDF am Ende hat.
 * Dieses Besteht inhaltlich aus allem, was DataValue enthält.
 * Zusätzlich kommt noch ein bereich bei dem man unterschreiben kann hinzu, welche untendrunter steht.
 */
export default {
  name: "PDFScreen",
  data() {
    return {
      currentDate: new Date(),
      storage: 0,
      newRows: 0
    };
  },
  props: ['xlsData'],
  methods: {
    /**
     * Die Methode conditionBlock() wird bei allen Zeitwerten aufgerufen.
     * Wenn die eingegebene Condition nicht "SA" oder "leer" ist, wird die Zeit als "--:--" dargestellt.
     * @param input der Parameter "input" enthält die Zeit, welche eigentlich dargestellt werden sollte.
     * @param index der Parameter "index" enthält die Reihe, welche betrachtet wird.
     * @param time der Parameter "time" enthält einen String, welcher nach dem "input" ausgegeben werden soll. Dieser ist meistens " h" oder " Uhr".
     * @returns {string|*} Die Methode gibt entweder "--:--" oder "input + time" zurück.
     */
    conditionBlock(input, index, time) {
      if (this.$store.state.shortCondition[index] == "K" || this.$store.state.shortCondition[index] == "U" || this.$store.state.shortCondition[index] == "UU" || this.$store.state.shortCondition[index] == "F") {
        return "--:--";
      } else {
        return input + time;
      }
    },
    /**
     * Die Methode timeFormat() wird benutzt, um Zahlen in Zeiten umzuformatieren.
     * Hierbei wird darauf geachtet, dass alles nach dem Bruch die Minuten bestimmt.
     * @param time Der Parameter time ist die Zahl, welche umformatiert werden soll.
     * @returns {*} Die Methode gibt die Zahl als Zeit aus.
     */
    timeFormat(time) {
      if (time == undefined) { //reiner Fail safe, falls eine leere Reihe erstellt wird. Würde dies fehlen, könnten leere Reihen das PDF kaputt machen.
        this.storage = this.storage + 0.33; //eine Leere Reihe ruft diese Methode immer 4-mal auf. Daher kommen die 0.25.
        if (this.storage == 0.99) {
          this.newRows = this.newRows + 1;
          this.storage = 0
        }
        return "null";
      }
      if (!time.toString().includes(".")) {
        time = time + ":00";
      } else {
        time = time.toString().replace(".",":")
      }
      if (time.charAt(2) == ":" && time.length == 4 || time.charAt(1) == ":" && time.length == 3) {
        time = time + "0";
      }
      if (time.charAt(2) == ":" && time.length > 5) {
        time = time.slice(0, 5);
      }
      if (time.charAt(1) == ":" && time.length > 4) {
        time = time.slice(0, 4);
      }
      return time;
    },
  }
}
</script>

<style>
  .pdfScreen .offset {
    margin-left: 25mm;
    font-size: xx-small;
  }
  .pdfScreen .page-break {
    height: 32px;
  }
  .pdfScreen .table {
    width: 165mm;
  }
  .pdfScreen .signitureBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20mm;
  }
  .pdfScreen .signiture {
    width: 60mm;
  }
  .pdfScreen .signitureLine{
    border-bottom: solid black 1px;
  }
  .pdfScreen .signitureText {
    font-size: x-small;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .pdfScreen .comment {
    max-width: 20mm;
    overflow: hidden;
  }
  .timeNoPause {
    margin: 0;
  }
  li {
    display: inline;
  }
  .height {
    padding-bottom: 20px;
  }
  .heading {
    margin-top: 26mm;
    margin-left: 25mm;
    font-size: large;
  }
</style>